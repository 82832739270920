import React, { useState, useEffect } from "react";
import styled from "styled-components";
import QRCode from "react-qr-code";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { Link } from 'react-router-dom';

function App() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [channelData, setChannelData] = useState(null);
  const [allConstituencies, setAllConstituencies] = useState([]);
  const [blockCodeData, setBlockCodeData] = useState(null);

  const getDataByBlockCode = async () => {
    try {
      const blockCodeValue = document.querySelector(".formField").value;

      if (blockCodeValue) {
        const response = await fetch(
          `https://backend.ptielections.com/api/v1/whatsapp/blockCode/${blockCodeValue}`
        );
        const data = await response.json();

        if (response.ok) {
          setBlockCodeData(data);
          setSelectedOption(data.constituency);
        } else {
          console.error("Error fetching block code data:", data);
          if (response.status === 404) {
          setBlockCodeData(null); 
          setSelectedOption(null);
          Swal.fire({
            icon: 'error',
            title: 'Block code not found',
            text: 'Please Enter a Valid Block code.',
          });
        }
        }
      } else if (!selectedOption && allConstituencies.length > 0) {
        setSelectedOption(allConstituencies[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedOption) {
      const encodedOption = encodeURIComponent(selectedOption);

      fetch(`https://backend.ptielections.com/api/v1/whatsapp/allData/${encodedOption}`)
        .then((response) => response.json())
        .then((data) => setChannelData(data[0]))
        .catch((error) => console.error("Error fetching channel data:", error));
    }
  }, [selectedOption]);

  useEffect(() => {
    fetch(`https://backend.ptielections.com/api/v1/whatsapp/allConstituencies`)
      .then((response) => response.json())
      .then((data) => setAllConstituencies(data))
      .catch((error) => console.error("Error fetching constituencies:", error));
  }, []);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };

  const handleJoinChannel = () => {
    if (channelData && channelData.url) {
      window.location.href = channelData.url;
    }
  };

  const handleJoinGroup = () => {
    if (
      channelData &&
      channelData.groupData &&
      channelData.groupData.length > 0
    ) {
      window.location.href = channelData.groupData[0].url;
    }
  };

  return (
    <>
      <Wrapper>
        <div className="container">
          <Box1 className="box1">
            <Heading>
          
              <img src="/logo-PTI.png" style={{ height:"auto", }} alt="Banner 2" />
             <span className="headings"> Pakistan Tehreek-e-Insaf (PTI)</span>
            </Heading>
            <BlockCodeSection>
              <BlockCodeField>
              <label style={{fontSize:"12px", marginBottom:"4px", fontWeight:"bold"}}>BlockCode</label>
                <input
                  className="formField"
                  type="number"
                  placeholder="Enter Block code"
                />
              </BlockCodeField>
              <BlockCodeField className="districts">  
              <label style={{fontSize:"12px", marginBottom:"4px", fontWeight:"bold"}}>District</label>
                
              <District>{blockCodeData?.district}</District>

                  </BlockCodeField>
                  {/* <BlockCodeField>  
              <label style={{fontSize:"12px", marginBottom:"4px", fontWeight:"bold",opacity:"0"}}>Constituency</label>
                
              <NA>{blockCodeData?.constituency}</NA>


                  </BlockCodeField> */}
                  <BlockCodeField className="butns">
                  <label style={{ opacity: "0" }}>sd</label>
              <Button onClick={getDataByBlockCode}>Get Data</Button>

                  </BlockCodeField>
            </BlockCodeSection>
            <GroupSelection className="flex-column">
              <Labeled>Constituencies</Labeled>
              <StyledSelectWrapper>
                <StyledSelect
                  onChange={handleSelectChange}
                  value={selectedOption}
                  className="selectBoxElement"
                >
                  <option value="">Select your Constituency</option>
                  {allConstituencies.map((constituency) => (
                    <option key={constituency} value={constituency}>
                      {constituency}
                    </option>
                  ))}
                </StyledSelect>
                <DropdownIcon>&#9660;</DropdownIcon>
              </StyledSelectWrapper>
            </GroupSelection>
            {selectedOption && (
              <QRCodeSection className="flex-row">
                <QRCodeWrapper>
                  <QRCodeImage value={channelData ? channelData.url : ""} />
                  <QRCodeHeading>Scan Channel QR Code</QRCodeHeading>
                  <Button onClick={handleJoinChannel}>Join Channel</Button>
                </QRCodeWrapper>
                {/* <QRCodeWrapper>
                  {channelData &&
                    channelData.groupData &&
                    channelData.groupData.length > 0 && (
                      <QRCodeImage value={channelData.groupData[0].url} />
                    )}
                  <QRCodeHeading>Scan Group QR Code</QRCodeHeading>
                  <Button onClick={handleJoinGroup}>Join Group</Button>
                </QRCodeWrapper> */}
              </QRCodeSection>
            )}
           <div className="banner">
           <a
              href="https://www.azadirazakar.com/"
              target="_blank"
              rel="noopener noreferrer"
          >
              <img
                  src="/BANNER.webp"
                  style={{ height: "159px", width: '100%', cursor: 'pointer' }}
                  alt="Banner 2"
              />
          </a>
          </div>
          </Box1>
          
        </div>
        
      </Wrapper>
    </>
  );
}

export default App;

const Heading = styled.span`


width:100%;
position:relative;
height: 100px;

img {
   height: 95px!important;
    object-fit: contain;
    position: absolute;
    left: -10px;
    top: -25px;
}
.headings{
  text-align: center;
  width: 100%;
  float: right;
  font-weight: bold;
  font-size:22px;
  }
  @media (max-width: 767px) {
    .headings{
       margin-top: 70px;

    }
    img {
    height: 95px!important;
    object-fit: contain;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%)!important;
    }
}

`;


const NA = styled.li`
  border: 1px solid grey;
  padding: 8px 20px;
  min-height:20px;
  min-width:80px;
  background: #CCCCCC;
`;

const District = styled.li`
  border: 1px solid grey;
  padding: 8px 20px;
  min-width:80px;
  min-height:20px;
  background: #CCCCCC;

`;

const BlockCodeSection = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 30px;
  min-height:39px;
  flex-wrap: wrap;
  list-style: none;
  @media (max-width: 767px) {
    position:relative;
    .butns{
      position:absolute;
      top:33%;
    }
    .districts{
      margin-top:19%;
    }
  }
`;

const BlockCodeField = styled.li`
display:flex;
flex-direction: column;
  .formField {
    padding: 10px 20px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  .container {
    width: 100%;
  }
  .flex-column {
    flex-direction: column;
    gap: 5px;
  }
  .flex-row {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #00684a, #600908);
`;

const StyledSelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledSelect = styled.select`
  width: 100%;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  background-color: #00684a;
  color: #fff;
  cursor: pointer;
  padding: 10px 30px 10px 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:hover {
    border-color: #45a049;
  }
  &:focus {
    border-color: #2e7d32;
    box-shadow: 0 0 0 0.2rem rgba(46, 125, 50, 0.25);
  }
  option {
    color: black;
    background-color:#fff;
  }
`;

const DropdownIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #fff;
`;

const Box1 = styled.div`
  background-color: #fff;
  height: fit-content;
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  gap: 20px;
  @media (max-width: 767px) {
    width: 80%;
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 27px;
  border-bottom: 1px solid #001f3f;
  width: 100%;
  padding-bottom: 10px;
  font-weight: bold;
`;

const GroupSelection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const QRCodeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const QRCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; 
`;

const QRCodeImage = styled(QRCode)`
  width: 110px !important; 
  height: 110px !important;
`;

const QRCodeHeading = styled.h3`
  color: #00684a;
  font-size: 14px;
  margin-top: 10px; /* Reduced margin for better alignment */
`;

const GroupSection = styled.div`
  background-color: #ff6f61;
  padding: 15px;
  margin-top: 15px;
  border-radius: 8px;
  width: 100%;
  color: #fff;
`;

const Button = styled.button`
  background-color: #00684a;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #00684a;
  }
`;

const Labeled = styled.label`
  text-align: left;
  width: 100%;
  font-weight: bold;
  font-size:12px;
`;
